import React from 'react';
import { Table, Typography } from 'antd';
import { Link, useStaticQuery, graphql } from 'gatsby';

import { Metadata } from '../components';

import StandardLayout from '../layouts/standard';

const ProductListPage = () => {

	const { markdownRemark } = useStaticQuery(
		graphql`
			query {
        		markdownRemark(fileAbsolutePath: {regex: "/content\/product-list/"}) {
					frontmatter {
						title
						products {
							worksCode
							worksTitle
							id
							price
							url
							description
							fileGUID
							name
						}
					}
					html
		  		}
      		}
    	`
	);

	const columns = [
		{
		  title: 'ID',
		  dataIndex: 'id'
		},
		{
		  title: 'Name',
		  dataIndex: 'name'
		},
		{
		  title: 'Price',
		  dataIndex: 'price'
		},
		{
		  title: 'URL',
		  dataIndex: 'url',
		  render: text => <Link to={ text }>{ text }</Link>
		},
		{
		  title: 'File GUID',
		  dataIndex: 'fileGUID'
		}
	  ];
	  
	  

	return (
		<StandardLayout>
			<Metadata title={ markdownRemark.frontmatter.title } />
			<Typography.Title level={2}>{markdownRemark.frontmatter.title}</Typography.Title>
			<Table rowKey="id" dataSource={markdownRemark.frontmatter.products} columns={columns} />;
		</StandardLayout>
	)
};

export default ProductListPage;
